var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ml-0",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card-title',{staticClass:"articulat-bold title-metas"},[_vm._v(" Todos os filtros ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tabs',{staticClass:"custom-tabs",attrs:{"background-color":"transparent","hide-slider":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{class:{ activeTab: _vm.selectedTab === 0 }},[_vm._v("Todos")]),_c('v-tab',{class:{ activeTab: _vm.selectedTab === 1 }},[_vm._v("Consultor")]),_c('v-tab',{class:{ activeTab: _vm.selectedTab === 2 }},[_vm._v(_vm._s(_vm.getNomeCDA()))])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticStyle:{"background-color":"white"},attrs:{"label":"Buscar","dense":"","clearable":"","hide-details":"","outlined":"","rounded":"","append-icon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-medium",attrs:{"headers":_vm.headersGeral,"items":_vm.filteredColaboradores,"item-value":"id","dense":"","fixed-header":"","loading-text":"Carregando...","footer-props":{
          itemsPerPageOptions: [-1],
        },"multi-sort":"","hide-default-footer":"","show-expand":"","single-expand":true,"expanded":_vm.expanded,"search":_vm.search},on:{"update:expanded":function($event){_vm.expanded=$event},"item-expanded":_vm.getColaboradorDetalhado},scopedSlots:_vm._u([{key:"item.etapas_metas",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"etapas-metas"},_vm._l((['meta_01', 'meta_02', 'meta_03']),function(meta,index){return _c('div',{key:index,staticClass:"step"},[_c('div',{staticClass:"circle",class:{ completed: _vm.isMetaConcluida(item.id, meta) }},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',{staticClass:"label",class:{ completed: _vm.isMetaConcluida(item.id, meta) }},[_vm._v(" "+_vm._s(_vm.getMeta(item.id, meta))+" "+_vm._s(item.cargo.nome === 'CDA' ? 'visitas' : 'matrículas')+" ")])])}),0)]}},{key:"item.total",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalVisitasById(item.id))+" "+_vm._s(item.cargo.nome === 'CDA' ? 'visitas' : 'matrículas')+" ")]}},{key:"item.bonificacao",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBonificacaoById(item.id))+" ")]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headersColaborador.length}},[_c('v-data-table',{staticClass:"table-ticket pa-4 articulat-medium",attrs:{"headers":_vm.getHeaders(item),"items":_vm.contratosPorColaborador,"dense":"","fixed-header":"","loading-text":"Carregando...","footer-props":{
                itemsPerPageOptions: [10, 20, 50, 100, -1],
              },"multi-sort":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.ultimo_contato",fn:function(ref){
              var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(value))+" ")]}},{key:"item.cda",fn:function(ref){
              var value = ref.value;
return [(value)?_c('span',[_vm._v(" "+_vm._s(value.full_name)+" ")]):_vm._e()]}},{key:"item.aluno_contatos",fn:function(ref){
              var item = ref.item;
return _vm._l((item.aluno_contatos),function(contato,idx){return _c('div',{key:idx},[(contato.observation)?_c('span',[_vm._v(_vm._s(contato.observation)+" -")]):_vm._e(),_vm._v(" "+_vm._s(contato.phone)),_c('br')])})}},{key:"item.curso_desconto_percentual",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v("Curso: "+_vm._s(item.curso_desconto_percentual)+"%")]),_c('br'),(!item.pre_invoice_id)?_c('span',[_vm._v("Material: "+_vm._s(item.material_didatico_desconto_percentual)+"%")]):_vm._e(),(item.pre_invoice_id)?_c('livro-solicitado-direto-franchise-button',{attrs:{"propPreInvoiceId":item.pre_invoice_id,"propPreInvoiceStatusId":item.pre_invoice_status_id}}):_vm._e()]}},{key:"item.turma_id",fn:function(ref){
              var item = ref.item;
return [(item.turma_codigo_referencia)?_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.turma_codigo_referencia)),"propObjectId":item.turma_id,"propToolName":"Turma"}}):_c('span',[_vm._v("N/D")])]}},{key:"item.curso_primeira_parcela_status_id",fn:function(ref){
              var item = ref.item;
return [(item.curso_primeira_parcela_status_id >= 0)?_c('div',[_c('v-chip',{attrs:{"color":_vm.getStatusFinanceiroParcelaStatus(
                    item.curso_primeira_parcela_status_id
                  ).color}},[_vm._v(" "+_vm._s(_vm.getStatusFinanceiroParcelaStatus( item.curso_primeira_parcela_status_id ).name)+" ")]),_c('small',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.curso_primeira_parcela_pagamento))+" ")])],1):_c('v-chip',{attrs:{"label":"","color":"error","rounded":""}},[_vm._v(" Não possui ")])]}},{key:"item.taxa_matricula_valor",fn:function(ref){
                  var item = ref.item;
return [(item.taxa_matricula_valor === 0)?_c('v-chip',{staticStyle:{"color":"#6400ac"},attrs:{"color":"#E6EDF4","rounded":""}},[_vm._v(" Isento ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatPriceValue(item.taxa_matricula_valor))+" ")])]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }